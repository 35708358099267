<ng-container *ngIf="!loading && !isErrorOccurred">
    <div class="version-card" style="min-width: 100px">
        <table>
            <thead>
            <tr>
                <th *ngFor="let head of data.headers">{{ head }}</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rowValues of data.values">
                    <td *ngFor="let value of rowValues; let i = index">{{ getFormattedValue(i, value) }}</td>
                </tr>
                <tr *ngIf="data.values.length === 0">
                    <td [colSpan]="data.headers.length">No history found</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100px">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!loading && isErrorOccurred">
    <div class="alert alert-danger">
        Error occurred while loading versions
    </div>
</ng-container>

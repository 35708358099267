<table>
    <tbody>
    <tr *ngFor="let item of items">
        <td style="font-weight: 500">{{ item.key }}:</td>
        <td>{{ item.value }}
            <ng-container *ngIf="item.actions?.length">
                <ng-container *ngFor="let action of item.actions">
                    <button class="btn btn-icon-primary btn-sm" (click)="onButtonClick(item.key, action.name)" style="padding: 5px 2px">
                        <i class="{{ action.icon }}"></i>
                    </button>
                </ng-container>
            </ng-container>
        </td>
    </tr>
    </tbody>
</table>

import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppLocalizationService } from "@app/shared/common/localization/app-localization.service";
import { AppNavigationService } from "@app/shared/layout/nav/app-navigation.service";
import { SpactCommonModule } from "@shared/common/common.module";
import { UtilsModule } from "@shared/utils/utils.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { AppAuthService } from "./auth/app-auth.service";
import { AppRouteGuard } from "./auth/auth-route-guard";
import { CommonLookupModalComponent } from "./lookup/common-lookup-modal.component";
import { EntityTypeHistoryModalComponent } from "./entityHistory/entity-type-history-modal.component";
import { EntityChangeDetailModalComponent } from "./entityHistory/entity-change-detail-modal.component";
import { DateRangePickerInitialValueSetterDirective } from "./timing/date-range-picker-initial-value.directive";
import { DatePickerInitialValueSetterDirective } from "./timing/date-picker-initial-value.directive";
import { DateTimeService } from "./timing/date-time.service";
import { TimeZoneComboComponent } from "./timing/timezone-combo.component";
import { NgxBootstrapDatePickerConfigService } from "assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { CountoModule } from "angular2-counto";
import { AppBsModalModule } from "@shared/common/appBsModal/app-bs-modal.module";
import { SingleLineStringInputTypeComponent } from "./input-types/single-line-string-input-type/single-line-string-input-type.component";
import { ComboboxInputTypeComponent } from "./input-types/combobox-input-type/combobox-input-type.component";
import { CheckboxInputTypeComponent } from "./input-types/checkbox-input-type/checkbox-input-type.component";
import { MultipleSelectComboboxInputTypeComponent } from "./input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { PasswordInputWithShowButtonComponent } from "./password-input-with-show-button/password-input-with-show-button.component";
import { KeyValueListManagerComponent } from "./key-value-list-manager/key-value-list-manager.component";
import { MomentBsDatePickerComponent } from "@app/shared/Components/moment-bs-datepicker/moment-bs-datepicker.component";
import { DataGridTooltipMessageComponent } from "./data-grid/data-grid-tooltip-message/data-grid-tooltip-message.component";
import { AdvancedFilterFormComponent } from "@app/shared/common/advanced-filter-form/advanced-filter-form.component";
import { NgSelectModule } from "@node_modules/@ng-select/ng-select";
import { StickyButtonsComponent } from "@app/shared/common/sticky-buttons/sticky-buttons.component";
import { CellLockModalComponent } from "@app/shared/common/cell-lock/cell-lock.component";
import { CompanyUevcbListComponent } from "@app/shared/common/company-uevcb-list/company-uevcb-list.component";
import { ProxyTableModule } from "@shared/common/proxy-table/proxy-table-module";
import { CommonDatepickerComponent } from "@app/shared/Components/common-datepicker/common-datepicker.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DataGridTooltipVersionHistoryComponent } from './data-grid/data-grid-tooltip-version-history/data-grid-tooltip-version-history.component';
import { SynchronizationCanvasComponent } from '@app/shared/common/components/synchronization-canvas/synchronization-canvas.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DataTableComponent } from "@app/shared/common/data-table/data-table.component";
import { InfoModalComponent } from '@app/shared/common/info-modal/info-modal.component';
import { CustomDropdownComponent } from '@app/shared/common/custom-dropdown/custom-dropdown.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        SpactCommonModule,
        TableModule,
        PaginatorModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        CountoModule,
        AppBsModalModule,
        AutoCompleteModule,
        NgSelectModule,
        ProxyTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatProgressSpinnerModule
    ],
  declarations: [
    TimeZoneComboComponent,
    CommonLookupModalComponent,
    EntityTypeHistoryModalComponent,
    EntityChangeDetailModalComponent,
    DateRangePickerInitialValueSetterDirective,
    DatePickerInitialValueSetterDirective,
    SingleLineStringInputTypeComponent,
    ComboboxInputTypeComponent,
    CheckboxInputTypeComponent,
    MultipleSelectComboboxInputTypeComponent,
    PasswordInputWithShowButtonComponent,
    KeyValueListManagerComponent,
    MomentBsDatePickerComponent,
    CommonDatepickerComponent,
    DataGridTooltipMessageComponent,
    AdvancedFilterFormComponent,
    StickyButtonsComponent,
    CellLockModalComponent,
    CompanyUevcbListComponent,
    DataGridTooltipVersionHistoryComponent,
    SynchronizationCanvasComponent,
    DataTableComponent,
    InfoModalComponent,
    CustomDropdownComponent
  ],
    exports: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        MomentBsDatePickerComponent,
        CommonDatepickerComponent,
        AdvancedFilterFormComponent,
        StickyButtonsComponent,
        CellLockModalComponent,
        CompanyUevcbListComponent,
        SynchronizationCanvasComponent,
        DataTableComponent,
        InfoModalComponent,
        CustomDropdownComponent
    ],
  providers: [
    DateTimeService,
    AppLocalizationService,
    AppNavigationService,
    { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
    { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
    { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale }
  ]
})
export class AppCommonModule {
  static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [AppAuthService, AppRouteGuard]
    };
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
    GetVersionHistoryRequestDto, IGetVersionHistoryRequestDto,
    InputMastersServiceProxy,
    VersionHistoryDto
} from "@shared/service-proxies/service-proxies";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { isNil } from "lodash-es";

@Component({
    selector: 'app-data-grid-tooltip-version-history',
    templateUrl: './data-grid-tooltip-version-history.component.html',
    styleUrls: ['./data-grid-tooltip-version-history.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe]
})
export class DataGridTooltipVersionHistoryComponent implements OnInit, OnDestroy {
    data: VersionHistoryDto;
    loading: boolean;
    isErrorOccurred: boolean;

    private readonly destroyed$ = new Subject<void>()

    constructor(
        private readonly inputMastersServiceProxy: InputMastersServiceProxy,
        private readonly datePipe: DatePipe,
        private readonly cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    setRequest(request: IGetVersionHistoryRequestDto) {
        this.loading = true;
        return new Promise(resolve => {
            request.maxItemCount ??= 10;
            this.inputMastersServiceProxy.getVersionHistory(new GetVersionHistoryRequestDto(request))
                .pipe(
                    takeUntil(this.destroyed$),
                    finalize(() => {
                        this.loading = false;
                        this.cdr.markForCheck();
                        resolve(true);
                    })
                )
                .subscribe(data => {
                    this.data = data;
                    this.cdr.markForCheck();
                }, () => {
                    this.isErrorOccurred = true;
                    this.cdr.markForCheck();
                })
        })
    }

    getFormattedValue(i: number, value: any) {
        const header = this.data.headers[i];
        const metadata = this.data.metadata[header];

        switch (metadata) {
            case 'DateTime':
                return this.datePipe.transform(value, 'dd.MM.yyyy HH:mm')
            case 'decimal':
                return !isNil(value) && !isNaN(value) && value.toFixed(2);
            default:
                return value;
        }
    }
}

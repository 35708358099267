export interface DataTableData {
    headers: DataTableHeader[];
    rows: DataTableRowData[];
}

export class DataTableHeader {
    name: string;
    sortable?: boolean;
    isHidden?: boolean;
    class?: string = "";
}

export class DataTableRowData {
    columns: DataTableColumnData[];
    isHidden?: boolean;
    class?: string = "";
}

export class DataTableColumnData {
    type: DataTableColumnType;
    value?: any;
    precisionSize?: number;
    imageUrl?: string;
    progressPercentage?: number;
    progressColorClass?: "success" | "danger" | "warning" | "info" | "primary";
    class?: string = "";
    textClass?: string = "";
}

export enum DataTableColumnType {
    Text,
    Number,
    Progress,
    AvatarAndName
}

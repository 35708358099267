import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    Output,
} from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

export interface ICustomDropdownItem {
    id: number;
    text: string;
}

@Component({
    selector: 'custom-dropdown',
    templateUrl: './custom-dropdown.component.html',
    styleUrls: ['./custom-dropdown.component.scss'],
    animations: [appModuleAnimation()]
})
export class CustomDropdownComponent extends AppComponentBase {

    @Input() items: ICustomDropdownItem[] = [];

    @Output() onOpen: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSelect: EventEmitter<ICustomDropdownItem> = new EventEmitter<ICustomDropdownItem>();

    constructor(injector: Injector) {
        super(injector);
    }

    select(item: ICustomDropdownItem) {
        this.onSelect.emit(item);
    }

    open() {
        let dropdownElement = document.getElementById("CustomDropdown");
        if (dropdownElement) {
            dropdownElement.style.display = "block";
            let menuItemElements = document.querySelectorAll(".custom-dropdown-menu .custom-dropdown-item");
            if (menuItemElements.length > 0) {
                this.clearAllHighlights();
                this.focusOnItem(0);
                this.onOpen.emit();
            }
        }
    }

    close() {
        this.clearAllHighlights();
        let dropdownElement = document.getElementById("CustomDropdown");
        if (dropdownElement) {
            dropdownElement.style.display = "none";
            this.onClose.emit();
        }
    }

    focusOnItem(index: number) {
        this.clearAllHighlights();
        let menuItemElements = document.querySelectorAll(".custom-dropdown-menu .custom-dropdown-item");
        if (menuItemElements.length > 0) {
            if (index >= menuItemElements.length) {
                let menuItemElement = menuItemElements[0] as HTMLElement;
                menuItemElement.className += " bg-light-primary text-primary";
                menuItemElement.scrollIntoView({ behavior: "smooth", block: "center" });
            } else {
                let menuItemElement = menuItemElements[index] as HTMLElement;
                menuItemElement.className += " bg-light-primary text-primary";
                menuItemElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }

    clearAllHighlights() {
        let menuItemElements = document.querySelectorAll(".custom-dropdown-menu .custom-dropdown-item");
        if (menuItemElements.length > 0) {
            menuItemElements.forEach(element => {
                let menuItemElement = element as HTMLElement;
                menuItemElement.className = menuItemElement.className.replace(" bg-light-primary text-primary", "");
            });
        }
    }
}

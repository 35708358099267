<div *ngIf="data" class="card-body p-0">
    <div class="table-responsive">
        <table class="table table-hover table-rounded table-row-bordered border data-table">
            <thead class="data-table-thead bg-light-secondary">
                <tr class="data-table-thead-row">
                    <th *ngFor="let header of data.headers; index as columnIndex;"
                        (click)="header.sortable && sort(columnIndex)"
                        [style.cursor]="header.sortable ? 'pointer' : 'default'"
                        [class]="'data-table-thead-text text-center p-3 ' + header.class">
                        <img *ngIf="header.sortable" src="/assets/common/icons/arrows-up-down.svg" alt="icons_arrows_up_down" />
                        <span class="fw-bold fs-7">{{header.name}}</span>
                    </th>
                </tr>
            </thead>
            <tbody class="data-table-tbody">
                <tr *ngFor="let row of data.rows" [class]="'data-table-tbody-row ' + row.class">
                    <td *ngFor="let column of row.columns; index as i;" [class]="'cursor-default p-2 ' + column.class">
                        <div [ngSwitch]="column.type">
                            <div *ngSwitchCase="DataTableColumnType.AvatarAndName" class="d-flex align-items-center gap-2">
                                <div class="avatar-container">
                                    <img [src]="column.imageUrl ? column.imageUrl : '/assets/data-table/icons/icons-ic-replace.svg'"
                                         class="icon avatar h-20px w-20px"
                                         alt="icon" />
                                </div>
                                <div [class]="column.textClass ?? 'fs-8'">{{column.value}}</div>
                            </div>
                            <div *ngSwitchCase="DataTableColumnType.Progress" class="d-flex align-items-center gap-1">
                                <div [class]="'progress h-6px w-100 bg-light-' + column.progressColorClass">
                                    <div [class]="'progress-bar h-6px bg-' + column.progressColorClass"
                                         [style.width]="column.progressPercentage + '%'"
                                         [attr.aria-valuenow]="column.progressPercentage"
                                         role="progressbar"
                                         aria-valuemin="0"
                                         aria-valuemax="100">
                                    </div>
                                </div>
                                <span [class]="column.textClass ?? 'fs-8'">{{column.value ? (column.precisionSize ? (column.value | number : '1.' + column.precisionSize + '-' + column.precisionSize) : column.value) : "-"}}</span>
                            </div>
                            <div *ngSwitchCase="DataTableColumnType.Number" class="d-flex flex-column align-items-center">
                                <span [class]="column.textClass ?? 'fs-8'">{{column.value ? (column.precisionSize ? (column.value | number : '1.' + column.precisionSize + '-' + column.precisionSize) : column.value) : "-"}}</span>
                            </div>
                            <div *ngSwitchCase="DataTableColumnType.Text" class="d-flex flex-column align-items-center">
                                <span [class]="column.textClass ?? 'fs-8'">{{column.value}}</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div id="CustomDropdown" class="custom-dropdown bg-white shadow rounded" style="display: none;">
    <div class="custom-dropdown-menu p-2 mh-300px min-w-250px">
        <div *ngFor="let item of items" (click)="select(item)" class="custom-dropdown-item bg-hover-light rounded p-2">
            <span class="text-hover-inverse-light bg-hover-light fw-bold">{{ item.text }}</span>
        </div>
        <div *ngIf="!items || items.length === 0" class="d-flex align-items-center justify-content-center">
            <span class="fw-bold">{{l("NoData")}}</span>
        </div>
    </div>
</div>

import { Injectable } from "@angular/core";
import Handsontable from "@node_modules/handsontable";
import {
    IProxyTableDefaultCellLockingStrategy
} from "@shared/common/proxy-table/locking/proxy-table-default-cell-locking-strategy.model";
import { CellLockDto } from "@shared/service-proxies/service-proxies";

@Injectable({ providedIn: 'root' })
export class ProxyTableDefaultCellLockingStrategy implements IProxyTableDefaultCellLockingStrategy {
    executeLock(table: Handsontable, data: any[], locks: CellLockDto[]) {
        const setting = table.getSettings();
        const lockedCompanyIds = locks.reduce((acc, i) => acc.concat(i.companyIds), []);
        const uniqueLocks = new Set(...lockedCompanyIds)

        for (let i = 0; i < setting.columns.length; i++) {
            const colData: string = setting.columns[i].data;
            const index = colData.split('.')[1];

            const colValue = data[0].values[index];
            const isLocked = uniqueLocks.has(colValue.companyId);
            for (let j = 0; j < 24; j++) {
                table.setCellMeta(j, i, 'readOnly', isLocked);
            }
        }
    }
}

import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from "@angular/core";
import {AppComponentBase} from "@shared/common/app-component-base";
import {ModalDirective} from "@node_modules/ngx-bootstrap/modal";

export class InfoModalDto<T> {

    item?: T;
    isLinkTextVisible?: boolean = false;
    linkText?: string;
    isCancelButtonVisible?: boolean = false;
    headerTitle?: string = "Information";
    headerTitleClass?: string = "fs-6 fw-boldest";
    headerIconSrc?: string;
    contentHtml?: string;
    buttonClass?: string = "btn btn-sm btn-outline-dark border border-dark";
    buttonIconSrc?: string;
    buttonText?: string = "OK";
    buttonTextClass?: string;

    constructor() {
        this.isLinkTextVisible = false;
        this.isCancelButtonVisible = false;
        this.headerTitle = "Information";
        this.headerTitleClass = "fs-6 fw-boldest";
        this.buttonClass = "btn btn-sm btn-outline-dark border border-dark";
        this.buttonText = "OK";
    }
}

@Component({
    selector: "info-modal",
    templateUrl: "./info-modal.component.html",
    styleUrls: ["./info-modal.component.scss"]
})
export class InfoModalComponent extends AppComponentBase implements OnInit {
    @ViewChild("infoModal", {static: true}) modal: ModalDirective;

    @Input() modalDto: InfoModalDto<any>;

    @Output() onConfirm: EventEmitter<typeof this.modalDto.item> = new EventEmitter<typeof this.modalDto.item>();
    @Output() onClickLinkText: EventEmitter<typeof this.modalDto.item> = new EventEmitter<typeof this.modalDto.item>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (!this.modalDto) {
            this.modalDto = new InfoModalDto<any>();
        }
    }

    show<T>(dto?: InfoModalDto<T>) {
        // Map dto to modalDto
        if (dto) {
            this.modalDto.item = dto.item ?? this.modalDto.item;
            this.modalDto.isLinkTextVisible = dto.isLinkTextVisible ?? this.modalDto.isLinkTextVisible;
            this.modalDto.linkText = dto.linkText ?? this.modalDto.linkText;
            this.modalDto.isCancelButtonVisible = dto.isCancelButtonVisible ?? this.modalDto.isCancelButtonVisible;
            this.modalDto.headerTitle = dto.headerTitle ?? this.modalDto.headerTitle;
            this.modalDto.headerTitleClass = dto.headerTitleClass ?? this.modalDto.headerTitleClass;
            this.modalDto.headerIconSrc = dto.headerIconSrc ?? this.modalDto.headerIconSrc;
            this.modalDto.contentHtml = dto.contentHtml ?? this.modalDto.contentHtml;
            this.modalDto.buttonClass = dto.buttonClass ?? this.modalDto.buttonClass;
            this.modalDto.buttonIconSrc = dto.buttonIconSrc ?? this.modalDto.buttonIconSrc;
            this.modalDto.buttonText = dto.buttonText ?? this.modalDto.buttonText;
            this.modalDto.buttonTextClass = dto.buttonTextClass ?? this.modalDto.buttonTextClass;
        }

        this.modal.show();
    }

    close() {
        this.modal.hide();
        this.onCancel.emit(this.modalDto.item);
    }

    clickConfirm() {
        // If item is not set, close the modal
        // (If you want to emit only, then assign an empty object to modalDto.item)
        if (!this.modalDto.item) {
            this.close();
        } else {
            this.onConfirm.emit(this.modalDto.item);
        }
    }

    clickLinkText() {
        if (!this.modalDto.item) {
            this.close();
        } else {
            this.onClickLinkText.emit(this.modalDto.item);
        }
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment-timezone";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { trLocale } from 'ngx-bootstrap/locale';

@Component({
    selector: "moment-bs-datepicker",
    styleUrls: ["moment-bs-datepicker.component.less"],
    templateUrl: "./moment-bs-datepicker.component.html"
})
export class MomentBsDatePickerComponent implements OnInit {
    _selectedDate: Date;

    @Input()
    set Monthly(param: boolean) {
        if (param == true) {
            this.bsConfig["minMode"] = "month";
            this.bsConfig["dateInputFormat"] = "YYYY-MM";
        }
    }

    @Input()
    Disabled: boolean;

    @Input()
    Clearable: boolean;

    @Input()
    LabelToLocalize: string;

    @Input()
    set Date(value: moment.Moment) {
        if (value) {
            this._selectedDate = value.toDate();
        }
    }

    get Date() {
        return moment(this._selectedDate);
    }

    @Output()
    DateChange = new EventEmitter<moment.Moment>();
    @Input()
    Id = undefined;
    @Input()
    MinDate: Date | undefined;
    @Input()
    MaxDate: Date | undefined;


    @Input()
    IsRequired = false;

    bsConfig = {
        dateInputFormat: "DD/MM/YYYY",
        containerClass: "theme-default",
        customTodayClass: 'custom-today-class'
    };

    constructor(
        private _localeService: BsLocaleService
    ) {
        if (this.Monthly == true) {

        }
    }

    ngOnInit() {
        this._localeService.use(trLocale.parentLocale);
    }

    onDateChange($event: Date) {
        if ($event) {
            this.Date = moment($event);
            this.DateChange.emit(this.Date);
        }
    }

    onClearClicked() {
        this._selectedDate = undefined;
        this.Date = undefined;
        this.DateChange.emit(undefined);
    }
}

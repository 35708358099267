import { isNil } from "lodash-es";
import { Pipe, PipeTransform } from "@angular/core";

const numberFormat = new Intl.NumberFormat('tr-TR', {
    style: 'decimal',
    minimumFractionDigits: 2, // Ensure two decimal places
    maximumFractionDigits: 2,
})
const priceFormat = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY'
})

export function decimalToLocalPrice(value: number | undefined, keepSymbol = true): string {
    if (isNil(value) || isNaN(value))
        return "-";

    if (keepSymbol) {
        return priceFormat.format(value);
    }

    return numberFormat.format(value);
}

@Pipe({ name: 'decimalToLocalPrice' })
export class DecimalToLocalPricePipe implements PipeTransform {
    transform(value: number | undefined, keepSymbol = true): string {
        return decimalToLocalPrice(value, keepSymbol)
    }
}

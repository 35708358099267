<div
    id="kt_drawer_synchronization"
    class="bg-body drawer drawer-end"
    data-kt-drawer="true"
    data-kt-drawer-name="synchronization"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'300px', 'md': '750px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_drawer_synchronization_toggle"
    data-kt-drawer-close="#kt_drawer_synchronization_close"
>
    <div class="card card-flush w-100">
        <div class="card-header pt-7">
            <div class="card-title">
                <span style="margin-right: 5px; display: inline-block">{{ 'SynchronizationControl' | localize }}</span>
                <mat-spinner [diameter]="18" *ngIf="loading || reloading"></mat-spinner>
            </div>

            <div class="card-toolbar justify-content-end">
                <a (click)="onReloadClick()" [class.disabled]="loading"
                   class="btn w-25px w-lg-40px h-25px h-lg-40px btn-icon btn-light btn-hover-primary reload-button">
                    <i class="flaticon2-reload"></i>
                </a>
                <a href="#" class="btn w-25px w-lg-40px h-25px h-lg-40px btn-icon btn-light btn-hover-primary"
                   id="kt_drawer_synchronization_close">
                    <i class="flaticon2-delete"></i>
                </a>
            </div>
        </div>
        <div class="card-body pt-5">
            <ng-container *ngIf="!loading && !!rows.length">
                <table class="table">
                    <thead>
                    <tr>
                        <th *ngIf="showExpandPanel()" style="max-width: 25px"></th>
                        <th>{{ 'Date' | localize }}</th>
                        <th>{{ 'Company' | localize }}</th>
                        <th>{{ 'OnePact' | localize }}</th>
                        <th>{{ 'Provider' | localize }}</th>
                        <th>{{ 'Synchronized' | localize }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let result of rows; let i = index">
                        <tr>
                            <td *ngIf="showExpandPanel()" class="expansion-row">
                                <i *ngIf="expandedIndex === i" class="fa fa-minus-square"
                                   (click)="onShowChildItemsClick(i)"></i>
                                <i *ngIf="expandedIndex !== i" class="fa fa-plus-square"
                                   (click)="onShowChildItemsClick(i)"></i>
                            </td>
                            <td>{{ result.creationTime.format('YYYY.MM.DD HH:mm') }}</td>
                            <td>{{ result.companyName }}</td>
                            <td class="text-center">
                                <i class="fa"
                                   [ngClass]="{ 'fa-check': result.providedByOnepact, 'fa-cancel': !result.providedByOnepact }"></i>
                            </td>
                            <td class="text-center">
                                <i class="fa"
                                   [ngClass]="{ 'fa-check': result.providedByProvider, 'fa-cancel': !result.providedByProvider }"></i>
                            </td>
                            <td class="text-center">
                                <ng-container *ngIf="!isBilateralContracts()">
                                    <i class="fa"
                                       [ngClass]="{ 'fa-check': result.isMatched, 'fa-cancel': !result.isMatched }"></i>
                                </ng-container>
                                <ng-container *ngIf="isBilateralContracts() && result.isAnyChildApproved">
                                    <i class="fa"
                                       [ngClass]="{ 'fa-check': result.isMatched, 'fa-cancel': !result.isMatched }"></i>
                                </ng-container>
                            </td>
                        </tr>
                        <tr [style.display]="expandedIndex === i ? 'table-row' : 'none'">
                            <td [colSpan]="6">
                                <table class="table" style="width: 100%">
                                    <thead>
                                    <tr>
                                        <th>{{ 'PowerPlant' | localize }}</th>
                                        <th>{{ 'OnePact' | localize }}</th>
                                        <th>{{ 'Provider' | localize }}</th>
                                        <th>{{ 'Synchronized' | localize }}</th>
                                        <th *ngIf="isBilateralContracts()">{{ 'Status' | localize }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let child of result.children">
                                        <td *ngIf="showPowerPlantName()">{{ child.powerPlantName }}</td>
                                        <td *ngIf="showCounterPartyName()">{{ child.counterpartyName }}</td>
                                        <td class="text-center">
                                            <i class="fa"
                                               [ngClass]="{ 'fa-check': child.providedByOnepact, 'fa-cancel': !child.providedByOnepact }"></i>
                                        </td>
                                        <td class="text-center">
                                            <i class="fa"
                                               [ngClass]="{ 'fa-check': child.providedByProvider, 'fa-cancel': !child.providedByProvider }"></i>
                                        </td>
                                        <td class="text-center">
                                            <ng-container *ngIf="!isBilateralContracts()">
                                                <i class="fa"
                                                   [ngClass]="{ 'fa-check': child.isMatched, 'fa-cancel': !child.isMatched }"></i>
                                            </ng-container>
                                            <ng-container *ngIf="isBilateralContracts()">
                                                <ng-container *ngIf="child.providerRecordStatus === 'APPROVED'">
                                                    <i class="fa"
                                                       [ngClass]="{ 'fa-check': child.isMatched, 'fa-cancel': !child.isMatched }"></i>
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                        <td *ngIf="isBilateralContracts()">
                                            <ng-container *ngIf="child.providerRecordStatus === 'APPROVED'">
                                                <span class="badge badge-success">{{ 'Approved' | localize }}</span>
                                            </ng-container>
                                            <ng-container *ngIf="child.providerRecordStatus === 'WAITING_FOR_APPROVAL'">
                                                <span class="badge badge-warning">{{ 'Pending' | localize }}</span>
                                            </ng-container>
                                            <ng-container *ngIf="child.providerRecordStatus === 'INVALID'">
                                                <span class="badge badge-danger">{{ 'Invalid' | localize }}</span>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="child.providerRecordStatus === null || child.providerRecordStatus === undefined">
                                                <span class="badge badge-secondary">{{ 'NotFound' | localize }}</span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </ng-container>

            <ng-container *ngIf="!loading && !rows.length">
                <div class="alert alert-warning">
                    {{ 'NoDataFound' | localize }}
                </div>
            </ng-container>
        </div>
    </div>
</div>




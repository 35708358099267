import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    DataGridTooltipMessageItem
} from "@app/shared/common/data-grid/data-grid-tooltip-message/data-grid-tooltip-message.models";


@Component({
    selector: 'app-data-grid-tooltip-message',
    templateUrl: './data-grid-tooltip-message.component.html',
    styleUrls: ['./data-grid-tooltip-message.component.css']
})
export class DataGridTooltipMessageComponent {
    items: DataGridTooltipMessageItem[] = [];

    @Output() onCellButtonClick = new EventEmitter<{ item: string, action: string }>();

    setData(data: DataGridTooltipMessageItem[]) {
        this.items = data;
    }

    onButtonClick(item: string, action: string){
        this.onCellButtonClick.emit({ item, action });
    }

}

import Handsontable from 'handsontable';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
    CustomValidatorGetter,
    DataConditions,
    DataTypes,
    IBasicDataSchema,
    IDataSchema,
    MinMaxNumberGetter,
    IConditionInfo
} from './DataSchema';
import * as _ from 'lodash';
import ColumnSettings = Handsontable.ColumnSettings;
import { MessageService } from 'abp-ng2-module';
import { HotTableComponent, HotTableRegisterer } from '@handsontable/angular';
import { LocalizePipe } from "@shared/common/pipes/localize.pipe";
import { isNil } from "lodash-es";
import {
    CellLockDto,
    CellLockRequestDto,
    CellLockServiceProxy, ICellLockRequestDto,
} from "@shared/service-proxies/service-proxies";
import { TableLockConfiguration } from "@shared/common/proxy-table/locking/table-lock.configuration";
import { PermissionPipe } from "@shared/common/pipes/permission.pipe";
import {
    ProxyTableDefaultCellLockingStrategy
} from "@shared/common/proxy-table/locking/proxy-table-default-cell-locking.strategy";
export type ConditionCallback = (valid: boolean, failReason?: DataConditions, value?: any, dataSchema?: IDataSchema, col?: Number, row?: Number) => void;

export interface IConfirmMessages {
    Values: IConfirmMessageValue[];
    ConditionInfo: IConditionInfo;
    HeaderName: string;
    MessageType: string;
}

export interface IConfirmMessageValue {
    Row: number;
    Value: number;
    ExpectedValue: number;
}

@Component({
    selector: 'proxy-table',
    templateUrl: './proxy-table.component.html',
    styleUrls: ['./proxy-table.component.css'],
    providers: [LocalizePipe, PermissionPipe]
})
export class ProxyTableComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() lockConfiguration: TableLockConfiguration;
    @Input() loading: boolean;
    @Input() settings?: Handsontable.GridSettings | undefined;
    @Input() data?: any | undefined;
    @Input() colDataSchema?: IDataSchema[] | undefined;
    @Input() rowDataSchema?: IBasicDataSchema[] | undefined;
    @Input() hotTableId = 'hotTableId';
    @Input() title?: string | undefined;
    @Input() showTitleInMessage?: boolean | undefined;
    @Input() defaultClassName: string | undefined = 'htCenter htMiddle';

    @ViewChild('hotTableRef') hotTable: HotTableComponent;

    hotRegisterer = new HotTableRegisterer();
    handsonLicenseKey = 'dc04c-e59db-625d4-e4036-23435';
    hotTableRegistered = false;

    private readonly _registeredValidator: string;

    private _hsd: Handsontable;
    private _validationStarted: ConditionCallback | undefined;
    private _messageShown: boolean;
    private _showMessage: boolean | undefined;
    private _confirmMessages: IConfirmMessages[] | undefined;
    private registeredLocks: CellLockDto[] = [];

    constructor(
        private readonly message: MessageService,
        private readonly cellLockServiceProxy: CellLockServiceProxy,
        private readonly localize: LocalizePipe,
        private readonly cellLockingStrategy: ProxyTableDefaultCellLockingStrategy,
        private readonly permissionPipe: PermissionPipe
    ) {
        const that = this;
        this._registeredValidator = 'proxy-validator-' + Math.round(Math.random() * 1000);
        Handsontable.validators.registerValidator(this._registeredValidator, function (value, callback) {
            that.validator(this, value, callback);
        });
        this.settings = {
            maxRows: this.rowDataSchema && this.rowDataSchema.length,
            columnSorting: false,
            stretchH: 'all',
            minSpareCols: 0,
            startRows: 0,
            className: this.defaultClassName,
            cells: function (row, col) {
                return { className: row % 2 === 0 ? 'even' : 'odd' };
            }
        };
    }

    ngOnInit() {
        if (this.lockConfiguration) {
            const canByPassLocking = this.lockConfiguration.bypassPermissions.some(perm => this.permissionPipe.transform(perm));

            if (canByPassLocking) {
                return;
            }

            this.registerLockedCompanies(this.lockConfiguration);
            this.registerLockingEventListeners();
        }
    }

    registerLockedCompanies(configuration: TableLockConfiguration) {
        this.cellLockServiceProxy.lockedCompanies(new CellLockRequestDto(configuration as ICellLockRequestDto)).pipe()
            .subscribe({
                next: locks => {
                    this.registeredLocks = locks;
                    this.lockUnlockTable(null);
                }
            });
    }

    lockUnlockTable(cellLock: CellLockDto) {
        if (cellLock) {
            if (cellLock.isLocked) {
                this.registeredLocks.push(cellLock);
            } else {
                for (let registeredLock of this.registeredLocks) {
                    registeredLock.companyIds = registeredLock.companyIds.filter(s => cellLock.companyIds.indexOf(s) < 0)
                }
            }
        }

        this.lockTableCellsForCompanies();
    }

    lockTableCellsForCompanies() {
        this.cellLockingStrategy.executeLock(this.handsontable, this.data, this.registeredLocks)
        this.handsontable.render();
    }

    registerLockingEventListeners() {
        abp.event.on('abp.dispatcher.lockUnlockCell', (data: CellLockDto) => {
            if (data.inputMasterType != this.lockConfiguration.inputMasterType) return;
            if(this.lockConfiguration.operationDate.diff(data.operationDate) != 0) return;
            this.lockUnlockTable(data);
        });
    }

    ngAfterViewInit(): void {
        this.hotTableRegistered = true;
        this.handsontable.updateSettings(this.settings, false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateInputs();

        if ('lockConfiguration' in changes && !changes.lockConfiguration.firstChange) {
            this.registerLockedCompanies(changes.lockConfiguration.currentValue);
        }
    }

    updateInputs() {
        const that = this;
        if (this.colDataSchema !== undefined) {
            let columns: Handsontable.ColumnSettings[] = [];
            let colHeaders: string[] = [];
            for (const key in this.colDataSchema) {
                const i = parseInt(key);
                const t = this.colDataSchema[i];
                if (_.isNil(t.TypeOption)) {
                    t.TypeOption = {};
                }
                const targetH = ['HeaderBackgroundColor', 'HeaderFontWeight', 'DataType', 'Conditions', 'ConditionOption', 'DisableValidation', 'CustomRenderer'];
                for (const k of targetH) {
                    if (!(k in t)) {
                        t[k] = undefined;
                    }
                }
                const targets = ['Source', 'TimeFormat', 'NumericFormat', 'DateFormat', 'CorrectInputFormat', 'DefaultDate'];
                for (const k of targets) {
                    if (!(k in t.TypeOption)) {
                        t.TypeOption[k] = undefined;
                    }
                }
                this.addOnPropChange(t);
                t['_self'].OnPropChange
                    = t['_self'].TypeOption['_self'].OnPropChange
                    = (key, value, dataSchema) => that.onPropChange(key, value, dataSchema);
                let colSettings: ColumnSettings = {
                    type: t.DataType,
                    data: <any>t.DataName,
                    renderer: t.CustomRenderer,
                    readOnly: t.ReadOnly,
                    source: t.TypeOption && t.TypeOption.Source,
                    timeFormat: t.TypeOption && t.TypeOption.TimeFormat,
                    correctFormat: t.TypeOption && t.TypeOption.CorrectInputFormat,
                    defaultDate: t.TypeOption && t.TypeOption.DefaultDate && t.TypeOption.DefaultDate.format(t.TypeOption.DateFormat || 'LLL'),
                    numericFormat: t.TypeOption && { pattern: t.TypeOption.NumericFormat, culture: 'en-US' },
                    dateFormat: t.TypeOption && t.TypeOption.DateFormat,
                    validator: this._registeredValidator,
                    allowEmpty: t.AllowEmpty,
                    width: t.ColumnWidth,
                };
                if (t.ClassName) {
                    colSettings.className = this.defaultClassName + ' ' + t.ClassName;
                }
                columns.push(colSettings);
                if (_.isNil(t.HeaderName)) {
                    t.HeaderName = this.getHeaderChar(i);
                }
                colHeaders.push(t.HeaderHtml || t.HeaderName);
            }
            let nestedHeaders: any;
            if (this.hasGroupHeaders()) {
                nestedHeaders = [this.getNestedHeaders(), colHeaders];
            }
            _.extend(this.settings, {
                columns: columns,
                colHeaders: nestedHeaders ? true : colHeaders,
                nestedHeaders: nestedHeaders,
                afterGetColHeader: function (col: number, elem: HTMLTableHeaderCellElement) {
                    const isTopHeader = elem.parentElement.nextSibling != null;
                    let cds: IBasicDataSchema = that.colDataSchema[col];
                    if (isTopHeader && cds) {
                        cds = (<IDataSchema>cds).HeaderGroup;
                    }
                    if (cds && cds.HeaderBackgroundColor !== undefined) {
                        elem.style.backgroundColor = cds.HeaderBackgroundColor;
                    }
                    if (cds && cds.HeaderFontWeight !== undefined) {
                        elem.style.fontWeight = cds.HeaderFontWeight;
                    }
                    if (cds && cds.HeaderFontColor !== undefined) {
                        elem.style.color = cds.HeaderFontColor;
                    }
                }
            });
        }
        if (this.rowDataSchema !== undefined) {
            let rowHeaders: string[] = [];
            for (const t of this.rowDataSchema) {
                rowHeaders.push(t.HeaderHtml || t.HeaderName);
            }
            _.extend(this.settings, {
                rowHeaders: rowHeaders,
                afterGetRowHeader: function (row: number, elem: HTMLTableHeaderCellElement) {
                    let cds = that.rowDataSchema[row];
                    if (cds && cds.HeaderBackgroundColor !== undefined) {
                        elem.style.backgroundColor = cds.HeaderBackgroundColor;
                    }
                    if (cds && cds.HeaderFontWeight !== undefined) {
                        elem.style.fontWeight = cds.HeaderFontWeight;
                    }
                }
            });
        }
        _.extend(this.settings, { data: this.data });
        this.updateSettings();

        if (this.lockConfiguration && this.handsontable) {
            this.lockTableCellsForCompanies();
        }
    }

    private getNestedHeaders() {
        let nested = [];
        const grouped = _.groupBy(this.colDataSchema, t => t.HeaderGroup.GroupName || t.HeaderGroup.HeaderHtml || t.HeaderGroup.HeaderName);
        for (const groupHeader in grouped) {
            const headerInfo = grouped[groupHeader][0].HeaderGroup;
            nested.push({
                label: headerInfo.HeaderHtml || headerInfo.HeaderName,
                colspan: grouped[groupHeader].length
            });
        }
        return nested;
    }

    private hasGroupHeaders() {
        return this.colDataSchema && this.colDataSchema.length > 0 && this.colDataSchema.every(t => !_.isNil(t.HeaderGroup));
    }

    updateSettings(settings?: Handsontable.GridSettings | undefined) {
        if (this.handsontable !== undefined) {
            this.validateSettings();
            this.handsontable.updateSettings(settings || this.settings, false);
            this.handsontable.validateCells();
        }
    }

    render() {
        if (this.handsontable !== undefined) {
            this.handsontable.render();
        }
    }

    validate(showMessage: boolean = true, callback?: ConditionCallback | undefined) {
        this._showMessage = showMessage;
        this._messageShown = false;
        this._validationStarted = callback;
        this._confirmMessages = [];
        this.handsontable.validateCells((valid) => {
            this._showMessage = undefined;
            this._validationStarted = undefined;
            if (valid && callback) {
                callback(true);
            } else if (this._confirmMessages && this._confirmMessages.filter(t => t.MessageType == 'confirm').length > 0) {
                let msg: string[] = [];
                for (let confirmMessage of _.sortBy(this._confirmMessages, x => x.HeaderName)) {
                    let invalidValuesMessage: string[] = [];
                    for (let value of _.sortBy(confirmMessage.Values, x => x.Row)) {
                        invalidValuesMessage.push(`<li><b>Row: ${value.Row}</b>: ${value.Value !== null ? value.Value : ''} ${value.ExpectedValue != null ? (value.ExpectedValue) : ''}</li>`);
                    }
                    msg.push(`<div><b>${confirmMessage.HeaderName}</b> following values dont meet <b>${confirmMessage.ConditionInfo.Name || DataConditions[confirmMessage.ConditionInfo.Condition]}</b> validation! \r\n</div> ${invalidValuesMessage}`);
                }
                const titleMessage = this.showTitleInMessage ? this.title + ' ' : '';
                this.message.confirm(titleMessage + msg.toString().replace(/,/g, '\r\n'), 'Are you sure to continue?', (isConfirm) => {
                    if (callback) {
                        callback(isConfirm == true);
                    }
                }, { isHtml: true });
            } else if (callback) {
                callback(this._confirmMessages !== undefined);
            }
            this._confirmMessages = undefined;
        });
    }

    get handsontable(): Handsontable {
        if (this._hsd === undefined && this.hotTableRegistered) {
            this._hsd = this.hotRegisterer.getInstance(this.hotTableId);
        }
        return this._hsd;
    }

    private addOnPropChange(t: any, path?: string, parent?: any) {
        let getPath = (p: string) => _.isNil(p) ? '' : p + '.';
        const self = _.extend({}, t);
        t['_self'] = self;
        for (const key in t) {
            if (key == '_self') {
                continue;
            }
            if (['TypeOption'].indexOf(key) > -1) {
                this.addOnPropChange(self[key], getPath(path) + key, t);
            }
            Object.defineProperty(t, key, {
                set: function (v) {
                    self[key] = v;
                    if (self.OnPropChange) {
                        self.OnPropChange(getPath(path) + key, v, parent || t);
                    }
                    return true;
                },
                get: function () {
                    return self[key];
                },
                enumerable: true,
                configurable: true
            });
        }
    }

    private onPropChange(key: string, value: any, dataSchema: IDataSchema): void {
        switch (key) {
            case 'HeaderHtml':
            case 'HeaderName':
                const i = this.colDataSchema.indexOf(dataSchema);
                this.settings.colHeaders[i] = value || this.getHeaderChar(i);
                break;
            case 'TypeOption.DefaultDate':
                _.extend(this.settings.columns[this.colDataSchema.indexOf(dataSchema)], { defaultDate: value.format(dataSchema.TypeOption && dataSchema.TypeOption.DateFormat || 'LLL') });
                break;
            case 'TypeOption.NumericFormat':
                _.extend(this.settings.columns[this.colDataSchema.indexOf(dataSchema)], {
                    numericFormat: {
                        pattern: value,
                        culture: 'en-US'
                    }
                });
                break;
            default:
                const names = {
                    'DataType': 'type',
                    'DataName': 'data',
                    'CustomRenderer': 'renderer',
                    'ReadOnly': 'readOnly',
                    'TypeOption.Source': 'source',
                    'TypeOption.TimeFormat': 'timeFormat',
                    'TypeOption.CorrectInputFormat': 'correctFormat',
                    'TypeOption.DateFormat': 'dateFormat',
                };
                if (names[key]) {
                    let r = {};
                    r[names[key]] = value;
                    _.extend(this.settings.columns[this.colDataSchema.indexOf(dataSchema)], r);
                }
                break;
        }
    }

    private validator(cellProp: Handsontable.CellProperties, value: Handsontable.CellValue, callback: (valid: boolean) => void): void {
        const cds = this.colDataSchema[cellProp.col];
        if (_.isNil(cds)) {
            return;
        }
        if (cds.DisableValidation === true) {
            callback(true);
            return;
        }
        const notEmpty = cds.Conditions && cds.Conditions.indexOf(DataConditions.NotEmpty) > -1;
        const rds = this.rowDataSchema[cellProp.row];
        const callOnConditionFail = (reason: DataConditions, minMaxValue?: number, optionIndex?: number) => {
            let conditionInfo = cds.ConditionInfo && cds.ConditionInfo.find(t => t.Condition == reason && (_.isNil(t.OptionIndex) || t.OptionIndex == optionIndex));
            let messageType = 'error';
            let nestedHeader = ""
            if (!_.isNil(cds.HeaderGroup)) {
                nestedHeader = cds.HeaderGroup.HeaderName + "->";
            }
            if (conditionInfo) {
                if (_.isNil(cellProp.className)) {
                    cellProp.className = '';
                }
                if (cellProp.className.indexOf(conditionInfo.ClassName) == -1) {
                    if (_.isArray(cellProp.className)) {
                        cellProp.className.push(conditionInfo.ClassName);
                    } else if (_.isString(cellProp.className)) {
                        cellProp.className += ' ' + conditionInfo.ClassName;
                    }
                }
                messageType = conditionInfo.MessageType || 'error';
            }
            if (this._showMessage && !this._messageShown) {
                const titleMessage = this.showTitleInMessage ? this.title + ' ' : '';
                if (messageType == 'error') {
                    this._confirmMessages = undefined;
                    this._messageShown = true;
                    const headerName = `${nestedHeader}${titleMessage}${cds.HeaderName} : ${rds.HeaderName}`;

                    switch (reason) {
                        case DataConditions.MustNumeric:
                            this.message.error(this.localize.transform('MustBeNumericValue', headerName, value), this.localize.transform('WrongInputValue'));
                            break;
                        case DataConditions.NotEmpty:
                            this.message.error(this.localize.transform('IsRequiredField', headerName), this.localize.transform('WrongInputValue'));
                            break;
                        case DataConditions.MaxNumber:
                            this.message.error(this.localize.transform('MustBeGreaterThan', headerName, value, `${conditionInfo ? conditionInfo.Name + '(' + minMaxValue + ')' : minMaxValue}`), this.localize.transform('WrongInputValue'));
                            break;
                        case DataConditions.MinNumber:
                            this.message.error(this.localize.transform('MustBeLessThan', headerName, value, `${conditionInfo ? conditionInfo.Name + '(' + minMaxValue + ')' : minMaxValue}`), this.localize.transform('WrongInputValue'));
                            break;
                        case DataConditions.OnlyNegative:
                            this.message.error(this.localize.transform('MustBeNegativeNumber', headerName, value), this.localize.transform('WrongInputValue'));
                            break;
                        case DataConditions.OnlyPositive:
                            this.message.error(this.localize.transform('MustBePositiveNumber', headerName, value), this.localize.transform('WrongInputValue'));
                            break;
                        case DataConditions.NonZero:
                            this.message.error(this.localize.transform('MustBeNonZeroValue', headerName, value), this.localize.transform('WrongInputValue'));
                            break;
                        case DataConditions.CustomValidator:
                            this.message.error(this.localize.transform('InputFieldIsNotValid', headerName, value), this.localize.transform('WrongInputValue'));
                            break;
                    }
                }
                if (this._confirmMessages) {
                    const confirmMessage = this._confirmMessages.find(x => x.ConditionInfo.Condition == reason && x.ConditionInfo.OptionIndex == optionIndex && x.MessageType == messageType && x.HeaderName == cds.HeaderName);
                    if (confirmMessage) {
                        confirmMessage.Values.push({
                            Row: cellProp.row,
                            Value: value,
                            ExpectedValue: minMaxValue,
                        });
                    } else {
                        this._confirmMessages.push({
                            ConditionInfo: conditionInfo,
                            HeaderName: cds.HeaderName,
                            MessageType: messageType,
                            Values: [{
                                Row: cellProp.row,
                                Value: value,
                                ExpectedValue: minMaxValue
                            }]
                        });
                    }
                }
            }
            if (this._validationStarted) {
                this._validationStarted(false, reason, value, cds, cellProp.col, cellProp.row);
            }
            cellProp.valid = false;
            callback(false);
        };

        const checkNumeric = () => {
            const hasValue = _.isNil(value) || value === '';
            if ((notEmpty && hasValue) || (!hasValue && !_.isNumber(value))) {
                callOnConditionFail(notEmpty && hasValue ? DataConditions.NotEmpty : DataConditions.MustNumeric);
                return false;
            }
            return true;
        };

        if (cds.DataType === DataTypes.Number) {
            if (!checkNumeric()) {
                return;
            }
        }
        //if there is no condition fail remove all condition class names
        this.resetValidationConditions(cds, cellProp);
        if (cds.Conditions) {
            for (const condition of cds.Conditions) {
                if (cds.DataType != DataTypes.Number &&
                    [DataConditions.MustNumeric,
                        DataConditions.OnlyNegative,
                        DataConditions.OnlyPositive,
                        DataConditions.MaxNumber,
                        DataConditions.MinNumber,
                        DataConditions.NonZero].indexOf(condition) > -1 &&
                    !checkNumeric()) {
                    return;
                }

                switch (condition) {
                    case DataConditions.CustomValidator:
                        const customValue = this.getCustomValue(cds.ConditionOption.CustomValidator, cellProp, value);
                        if (!cds.ConditionOption) {
                            throw new Error('"DataConditions.CustomValidator" need "IDataSchema.ConditionOption.CustomValidator"');
                        }
                        for (const target of customValue) {
                            if (!_.isNil(target) && !_.isNil(value) && !target) {
                                callOnConditionFail(condition, undefined, customValue.indexOf(target));
                                return;
                            }
                        }
                        break;
                    case DataConditions.MaxNumber:
                        const maxNumber = this.getMinMaxNumber(cds.ConditionOption.MaxNumber, cellProp, value);
                        if (!cds.ConditionOption) {
                            throw new Error('"DataConditions.MaxNumber" need "IDataSchema.ConditionOption.MaxNumber"');
                        }
                        for (const target of maxNumber) {
                            if (!_.isNil(target) && !_.isNil(value) && value > target) {
                                callOnConditionFail(condition, target, maxNumber.indexOf(target));
                                return;
                            }
                        }
                        break;
                    case DataConditions.MinNumber:
                        const minNumber = this.getMinMaxNumber(cds.ConditionOption.MinNumber, cellProp, value);
                        if (!cds.ConditionOption) {
                            throw new Error('"DataConditions.MinNumber" need "IDataSchema.ConditionOption.MinNumber"');
                        }
                        for (const target of minNumber) {
                            if (!_.isNil(target) && !_.isNil(value) && value < target) {
                                callOnConditionFail(condition, target, minNumber.indexOf(target));
                                return;
                            }
                        }
                        break;
                    case DataConditions.NonZero:
                        if (!_.isNil(value) && value === 0) {
                            callOnConditionFail(condition);
                            return;
                        }
                        break;
                    case DataConditions.NotEmpty:
                        if (_.isNil(value) || (_.isString(value) && value === '')) {
                            callOnConditionFail(condition);
                            return;
                        }
                        break;
                    case DataConditions.OnlyNegative:
                        if (!_.isNil(value) && value > 0) {
                            callOnConditionFail(condition);
                            return;
                        }
                        break;
                    case DataConditions.OnlyPositive:
                        if (!_.isNil(value) && value < 0) {
                            callOnConditionFail(condition);
                            return;
                        }
                        break;
                }
            }
        }
        callback(true);
    }

    private resetValidationConditions(cds: IDataSchema, cellProp: Handsontable.CellProperties) {
        if (cds.ConditionInfo && cds.ConditionInfo.length > 0) {
            for (const className of cds.ConditionInfo.map(t => t.ClassName).filter((v, i, s) => s.indexOf(v) === i)) {
                let loc = cellProp.className.indexOf(className);
                if (loc > -1) {
                    if (_.isArray(cellProp.className)) {
                        cellProp.className.splice(loc, 1);
                    } else if (_.isString(cellProp.className)) {
                        cellProp.className = cellProp.className.replace(className, '').trim();
                    }
                }
            }
        }
    }

    validateIfAllValuesAreEmpty() {
        const data = this.handsontable.getData();
        const allValuesAreEmpty = data.every(row => row.every(value => isNil(value)));

        if (allValuesAreEmpty) {
            abp.message.error(this.localize.l('DatatableAllValuesAreEmptyWarning'));
        }

        return allValuesAreEmpty;
    }

    private getCustomValue(target: CustomValidatorGetter | CustomValidatorGetter[], cellProp: Handsontable.CellProperties, value: any): boolean[] {
        let instance = this;

        function get(method) {
            return method.apply(instance, [cellProp.col, cellProp.row, cellProp.prop, value]);
        }

        return _.isArray(target)
            ? (<any[]>target).map(t => get(t))
            : [get(target)];
    }

    private getMinMaxNumber(target: number | MinMaxNumberGetter | number[] | MinMaxNumberGetter[], cellProp: Handsontable.CellProperties, value: any): number[] {
        let instance = this;

        function get(single) {
            return _.isFunction(single)
                ? single.apply(instance, [cellProp.col, cellProp.row, cellProp.prop, value])
                : single;
        }

        return _.isArray(target)
            ? (<any[]>target).map(t => get(t))
            : [get(target)];
    }

    private getHeaderChar(n: number): string {
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA + 1;

        let s = '';
        while (n >= 0) {
            s = String.fromCharCode(n % len + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s;
    }

    private validateSettings() {
        if (this.settings.columns) {
            for (let columnsKey in this.settings.columns) {
                const colSettings = this.settings.columns[columnsKey]
                if (colSettings) {
                    for (let colSettingsKey in colSettings) {
                        if (colSettings[colSettingsKey] === undefined) {
                            delete colSettings[colSettingsKey];
                        }
                    }
                }
            }
        }
    }
}

<div class="card card-custom mb-3">
    <div class="card-header custom-card-header">
        <div class="d-flex align-items-center justify-content-between width-percent-100">
            <div class="filter-text">
                <div class="row advanced-filter-row" style="min-width: 600px">
                    <div class="col-md-6 form-inline" *ngIf="hasOperationDate">
                        <button class="d-inline-block operation-date-jump-day" style="border: none; font-size: large;" (click)="onOperationDateSubtract()" title="{{ 'Before Day' | localize}}"><</button>
                        <div class="input-group d-inline-block" style="width: auto">
                            <moment-bs-datepicker
                                #operationDateIpnput
                                [Id]="operationDate"
                                [(Date)]="operationDate"
                                [Clearable]="false"
                                (DateChange)="onOperationDateChange($event)"
                                [LabelToLocalize]="'OperationDate'">
                            </moment-bs-datepicker>
                        </div>
                        <button  class="d-inline-block operation-date-jump-day" style="border: none; font-size: large;" (click)="onOperationDateAdd()" title="{{ 'After Day' | localize}}"> ></button>
                    </div>
                    <div class="col-md-6" *ngIf="showVersion && hasInputMaster">
                        <div class="form-label">Version</div>
                        <ng-select [items]="versions" bindLabel="name" bindValue="id" id="inputMasterId"
                                   placeholder="{{ (versions.length > 0 ? 'CurrentVersion' : 'NoVersion') | localize }}" [(ngModel)]="inputMasterId"
                                   [disabled]="versionsLoading" [ngModelOptions]="{ standalone: true }"
                                   [clearable]="true" [loading]="versionsLoading" (ngModelChange)="onVersionChange($event)">
                        </ng-select>
                    </div>
                </div>
                <div class="row advanced-filter-row" style="min-width: 600px">
                    <div class="col-6 margin-top-5"></div>
                    <div class="col-6 margin-top-5" *ngIf="showVersion && hasInputMaster">
                        <span>{{versionInfo}}</span>
                    </div>
                </div>
            </div>
            <button class="btn btn-secondary btn-sm" (click)="onToggleAdvancedFilters()">{{ (showAdvancedFilters ? 'HideAdvancedFilters' : 'ShowAdvancedFilters') | localize }}</button>
        </div>
    </div>
    <div class="card-body" *ngIf="showAdvancedFilters">
        <ng-content></ng-content>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-end" *ngIf="showAdvancedFilters">
        <button class="btn btn-default btn-sm margin-right-3" (click)="onResetButtonClick()">{{ 'Clear' | localize}}</button>
        <button class="btn btn-info btn-md-icon btn-sm" (click)="onApplyFilterButtonClick()" [disabled]="filtersButtonDisabled">
            <i class="fa fa-filter"></i> {{ 'Filter' | localize }}
        </button>
    </div>
</div>
